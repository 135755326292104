<template>
  <BaseLanguagePicker :value="$i18n.locale" @input="onLocaleChanged" :include="supportedLanguages" :uiLocale="uiLocale"
    @close="$emit('close')" :showClose="false" />
</template>

<script>
import { getSupportedLanguages, getTwoCodeLowercaseBrowserLanguages } from "@/i18n";

import moment from "moment";

export default {
  methods: {
    onLocaleChanged (loc) {
      this.$i18n.locale = loc;

      moment.locale(this.$i18n.locale);

      window.localStorage.setItem("preferred_locale", loc);
    },
  },
  computed: {
    supportedLanguages () {
      const allLanguages = ["en"];

      const appSupportedLanguages = getSupportedLanguages();
      const browserSupportedLanguages = getTwoCodeLowercaseBrowserLanguages();

      const testAndPushLocale = (locale) => {
        console.log(`[UITranslationsManager] Testing locale: ${locale}`);
        if (appSupportedLanguages.includes(locale) && browserSupportedLanguages.includes(locale)) {
          allLanguages.push(locale);
        }
      }

      if (this.$store.state.menus && this.$store.state.menus.length) {
        for (const menu of this.$store.state.menus) {
          testAndPushLocale(menu.original_locale);

          if (menu.locales) {
            menu.locales.map(l => testAndPushLocale(l));
          }
        }
      }

      if (this.$store.state.cafe && this.$store.state.cafe.ui_settings && this.$store.state.cafe.ui_settings.locale_override) {
        allLanguages.push(this.$store.state.cafe.ui_settings.locale_override);
      }

      const result = Array.from(new Set(allLanguages));

      console.log(
        `[UITranslationsManager] Available languages: ${result.join(", ")}, browser = ${browserSupportedLanguages.join(", ")}, app = ${appSupportedLanguages.join(", ")}`
      );

      return result;
    },
    uiLocale () {
      const uiLocales = ["en", "ru", "uk"];

      return uiLocales.includes(this.$i18n.locale) ? this.$i18n.locale : "en";
    },
  },
};
</script>

<style></style>
