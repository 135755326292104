<template>
  <div class="home-hero" :class="{ 'collapsed': collapsed }">
    <div class="cafe-general-info" :class="{ collapsed }">
      <div class="gap-row-xs w-100">
        <div class="cafe-general-logo-row" v-show="!collapsed">
          <img :src="cafe.image" class="cafe-logo animate" v-if="cafe.image" />
          <BaseWorkspaceAvatar :size="72" v-else />
          <FlagIcon @click="$emit('switchLanguage')" />
        </div>

        <div class="cafe-titles" :class="{
          'gap-row-1xs': !collapsed,
          'collapsed-titles': collapsed,
        }">
          <div class="cafe-name-title bold qrw-text-content-base-primary" :class="{
            'qrw-heading-sm': !collapsed,
            'qrw-label-lg': collapsed,
          }">
            {{ cafe.name }}
          </div>
          <div class="gap-column-xs description-row animate">
            <div class="qrw-text-content-base-secondary w-100" :class="{
              'qrw-label-sm': !collapsed,
              'qrw-label-xs': collapsed
            }" v-if="cafe.description">
              {{ cafe.description.split(" ").slice(0, 5).join(" ") }}
            </div>
            <div class="qrw-label-sm qrw-text-content-base-primary animate gap-column-3xs"
              v-show="!collapsed && priceLevelAsNumber">
              <span
                :class="n <= priceLevelAsNumber ? 'qrw-text-content-base-primary' : 'qrw-text-content-base-tertiary'"
                v-for="n in 4" :key="n">
                $
              </span>
            </div>
          </div>
        </div>
      </div>

      <FlagIcon @click="$emit('switchLanguage')" v-show="collapsed" />

      <!-- <div class="language-switcher"></div> -->
    </div>

    <CafeCallRow class="animate" v-show="showContacts && cafe._id == 567" v-qrw-class-on-scroll="{
      limit: 187,
      returnLimit: 1,
      cssClass: 'h-call-row-scrolled'
    }" />
  </div>
</template>

<script>
import CafeCallRow from '@/components/home/CafeCallRow.vue'

export default {
  props: {
    collapsed: Boolean,
    showContacts: Boolean
  },
  components: {
    CafeCallRow
  },
  computed: {
    cafe () {
      return this.$store.state.cafe
    },
    priceLevelAsNumber () {
      if (!this.cafe.price_level) return

      return this.cafe.price_level.split('').length
    }
  }
}
</script>

<style scoped>
.cafe-general-info {
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  transition: 0.3s all;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

.language-switcher {
  position: absolute;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 24px;
  right: 24px;
}

.cafe-logo {
  box-shadow: var(--qrw-shadow-card-light);
  /* width: 80px;
  height: 80px;

  max-width: 80px;
  min-height: 80px; */
  height: 78px;
  width: auto;
  max-height: 78px;
  min-height: 78px;

  border-radius: 16px;
  object-fit: cover;
  border: 1px solid var(--qrw-border-base-tertiary);
  transition: 0.3s all;
}

.cafe-general-logo-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: 0.3s all;
  align-items: flex-start;
}

.home-hero {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 16px;

  /* position: fixed; */
  /* top: 0; */
  /* z-index: 30; */

  width: 100%;
  box-sizing: border-box;
  /* background: var(--qrw-background-base-secondary-10); */
  transition: 0.3s all;
}

.home-hero.collapsed {
  /* background: var(--qrw-background-base-secondary-10); */
  padding: 12px 16px 8px 16px;
  transition: 0.3s all;
}

.cafe-name-title {
  transition: 0.3s all;
}

.animate {
  transition: 0.3s all;
}

.description-row {
  align-items: center;
}

.cafe-general-info.collapsed {
  align-items: center;
}

.collapsed-titles {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
</style>

<style>
.h-call-row-scrolled {
  display: none;
}
</style>
